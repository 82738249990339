import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import styles from './Page.module.scss';

type Props = {
  title?: string,
  children: React.Node,
  date?: string,
};

const Page = ({ title, date, children }: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    const currentPath = window?.location?.pathname;
    const isRootPath = currentPath === '/';
    if (!isRootPath) {
      pageRef.current?.scrollIntoView();
    }
  });

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        { date && <p className={styles['page__date']}>Updated {moment(date).format('D MMM YYYY')}</p>}
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;